import { mapState } from 'vuex'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import { helpers, required } from 'vuelidate/lib/validators'
import axios from 'axios'
import EventBus from '@/event-bus'
import _ from 'lodash'

const customAccountCodeRule = helpers.regex('customAccountCodeRule', /^\d{4,6}$/)
const validPercentage = helpers.regex('customAccountCodeRule', /^(100|[0-9]{1,2})$/)
const isCodeUnique = getter => function () {
  return this.is_code_unique
}

export default {
  props: ['initialData', 'typeOfAccount', 'finalModel', 'isUpdate'],
  data () {
    return {
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      translation_path: 'incomes_expenses.accounts.create_account_wizard.common.step3.',
      translation_path_step3: 'incomes_expenses.accounts.create_account_wizard.income.step3.',
      account_percentage: 40,
      status: 'active',
      account_code: null,
      is_capital_account: 0,
      globalAccounts: [],
      global_id: null,
      showOptions: false,
      loadingGlobalAccounts: false,
      description: null,
      disabledDates: {
        to: new Date()
      },
      validated: false,
      is_code_unique: true,
      account_id: null,
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      }
    }
  },
  validations: {
    account_code: {
      customAccountCodeRule,
      codeUnique: isCodeUnique()
    },
    account_percentage: {
      required,
      validPercentage
    },
    status: {
      required
    },
    is_capital_account: {
      required
    },
    form: ['account_code', 'status', 'is_capital_account', 'account_percentage']
  },
  mounted () {
    if (this.currentUser && Object.prototype.hasOwnProperty.call(this.currentUser, 'email') && this.currentUser.email) {
      this.loadingGlobalAccounts = true
      this.fetchGlobalAccounts(this.typeOfAccount, this.currentUser.default_lang)
        .then(data => {
          const AllAccountsGlobals = Object.prototype.hasOwnProperty.call(data, 'accounts') ? data.accounts : []
          this.globalAccounts = AllAccountsGlobals
          this.setGlobalAccount()
          this.loadingGlobalAccounts = false
        })
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    passivePercentageValue () {
      return 100 - this.account_percentage
    },
    invalidAccountPercentageFeedback () {
      return this.$v.account_percentage.$error ? this.$i18n.t('common.errors.numeric') : ''
    },
    globalAccountsOptions () {
      const accountsOptions = this.globalAccounts.map(item => {
        const accountsValues = item.accounts.map(AccItem => {
          return Object.assign(AccItem, {
            label: this.currentCOA.prefix_account_title_with_code ? AccItem.title_with_code : AccItem.title
          })
        })
        const groupType = this.$te('common.' + item.type) ? this.$t('common.' + item.type) : item.type
        const label = groupType + ' / ' + item.title
        return Object.assign(item, {
          label,
          accounts: accountsValues
        })
      })
      return accountsOptions
    },
    currentLang () {
      return this.$i18n.locale
    },
    aStatusOptions () {
      return [
        { text: this.$t(this.translation_path + 'active'), value: 'active' },
        { text: this.$t(this.translation_path + 'suspended'), value: 'suspended' }
      ]
    },
    aCapitalAccountOptions () {
      return [
        { text: this.$t('common.no'), value: 0 },
        { text: this.$t('common.yes'), value: 1 }
      ]
    },
    displaySuspended () {
      return this.status === 'suspended'
    },
    invalidStatusFeedback () {
      if (this.$v.status.$error) {
        return this.$t(this.translation_path + 'errors.status_required')
      } else {
        return ''
      }
    },
    invalidCapitalAccountFeedback () {
      if (this.$v.is_capital_account.$error) {
        return this.$t(this.translation_path + 'errors.is_capital_account_required')
      } else {
        return ''
      }
    },
    invalidAccountCodeFeedback () {
      if (this.$v.account_code.customAccountCodeRule === false) { return this.$i18n.t(this.translation_path + 'errors.code_invalid') }
      if (this.$v.account_code.codeUnique === false) { return this.$i18n.t(this.translation_path + 'errors.code_already_taken') }
      return ''
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    isValidForm () {
      return !this.$v.form.$invalid
    }
  },
  methods: {
    async suggestionCode (groupId) {
      await this.fetchIdentityCode(groupId)
        .then(responseData => {
          this.account_code = responseData && Object.keys(responseData).indexOf('suggested_code') > -1 ? responseData.suggested_code : null
        })
    },
    async validate () {
      this.$v.form.$touch()
      let isValid = this.isValidForm
      var self = this
      const postData = {
        only_validate: true,
        title: this.finalModel.account_title,
        default_active_percentage: this.default_active_percentage,
        type: this.typeOfAccount,
        status: this.status,
        is_capital_account: this.is_capital_account,
        code: this.account_code,
        global_id: this.global_id ? this.global_id.id : null,
        description: this.description
      }

      const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/accounts' + (this.isUpdate ? '/' + this.account_id : '')
      const sApiMethod = this.isUpdate ? 'put' : 'post'
      if (!this.isValidForm && !this.showOptions) {
        this.showOptions = true
      }
      // Validate that code is unique on create
      if (isValid) {
        const responseValidate = await axios({
          method: sApiMethod,
          url: sApiEndpoint,
          data: postData
        })
          .then(responseData => {
            const data = {
              status: this.status,
              account_code: this.account_code,
              is_capital_account: this.is_capital_account,
              global_id: this.global_id ? this.global_id.id : null,
              account_percentage: this.account_percentage,
              description: this.description
            }
            this.$emit('on-validate', data)
            return isValid
          })
          .catch(function (error) {
            isValid = false
            if (error.response.status === 422 && typeof (error.response.data.error.data.code) !== 'undefined') {
              self.is_code_unique = false
            }
            if (error.response.status === 422 && typeof (error.response.data.error.data.title) !== 'undefined') {
              EventBus.$emit('make-toast', {
                text: this.$t(this.translation_path + 'errors.title_already'),
                status: 'danger'
              })
            }
            if (!self.is_code_unique && !self.showOptions) {
              self.showOptions = true
            }
            return isValid
          })
        return responseValidate
      } else {
        return isValid
      }
    },
    dateFormatter (date) {
      return this.currentCOA.locale === 'sv-SE' ? this.$moment(date).format('YYYY-MM-DD') : this.$moment(date).format('MM-DD-YYYY')
    },
    setGlobalAccount () {
      const accounts = this.globalAccounts.map(item => item.accounts)
      const deepEqualAccounts = _.flattenDeep(accounts).filter(item => item.id === this.global_id).map(item => {
        return Object.assign(item, {
          label: this.currentCOA.prefix_account_title_with_code ? item.title_with_code : item.title
        })
      })
      this.global_id = deepEqualAccounts.length > 0 ? deepEqualAccounts[0] : null
    },
    optionChangeHandler () {
    }
  },
  watch: {
    initialData: {
      handler: function (val) {
        if (Object.keys(val).length > 0) {
          this.account_id = val.id
          this.status = val.status
          this.$v.status.$reset()
          this.is_capital_account = val.is_capital_account
          this.account_percentage = Object.prototype.hasOwnProperty.call(val, 'default_active_percentage') && val.default_active_percentage ? val.default_active_percentage : 0
          this.account_code = Object.prototype.hasOwnProperty.call(val, 'code') && val.code ? val.code : null
          this.description = Object.prototype.hasOwnProperty.call(val, 'description') && val.description ? val.description : null
        }
      },
      immediate: true,
      deep: true
    },
    account_code () {
      this.is_code_unique = true
    },
    importable () {
      if (this.importable === 'no') {
        this.importableValue = null
      }
    },
    currentUser: function (newVal) {
      if (newVal && Object.prototype.hasOwnProperty.call(newVal, 'email') && newVal.email) {
        this.loadingGlobalAccounts = true
        this.fetchGlobalAccounts(this.typeOfAccount, this.currentUser.default_lang)
          .then(data => {
            const AllAccountsGlobals = Object.prototype.hasOwnProperty.call(data, 'accounts') ? data.accounts : []
            this.globalAccounts = AllAccountsGlobals
            this.setGlobalAccount()
            this.loadingGlobalAccounts = false
          })
      }
    }
  }
}
