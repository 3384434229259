import axios from 'axios'
const fetchIdentityCode = (accountGroupId) => {
  return axios.get(`${process.env.VUE_APP_ROOT_API}/accountant/suggest-account-code/${accountGroupId}`)
    .then(response => response.data.data)
    .then(res => {
      return res
    })
    .catch(err => {
      console.error(err)
      return {}
    })
}

const fetchGlobalAccounts = (accountType, locale) => {
  return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/global?account_type=${accountType}&locale=${locale}`)
    .then(response => response.data.data)
    .then(res => {
      return res
    })
    .catch(err => {
      console.error(err)
      return {}
    })
}
const fetchInBucket = (bucketType, locale) => {
  if (locale) {
    return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=asset,liability&in_bucket=${bucketType}&sort[0][key]=type&sort[0][direction]=desc&sort[1][key]=title&sort[1][direction]=asc&fetch_amount=1`)
      .then(response => response.data.data)
      .then(res => {
        const filter = function (accountType, isFreeCapital) {
          const filtered = res.filter(item => item.type === accountType && item.is_bucket_free_capital === isFreeCapital).sort(function (a, b) {
            const nameA = a.title
            const nameB = b.title
            return nameA.localeCompare(nameB, locale)
          })

          if (!filtered.length) {
            return [{
              belongs_to: 'no_record',
              title: null,
              amount: null,
              type: accountType
            }]
          }

          return filtered
        }

        const calculateTotal = function (items) {
          let total = 0
          if (items.length) {
            total = items.map(item => item.amount).reduce((acc, item) => {
              return parseFloat(acc) + parseFloat(item)
            })
          }
          if (total === null) {
            total = 0
          }
          return parseFloat(total)
        }

        const assetsFree = filter('asset', 1)
        const assetsTied = filter('asset', 0)
        const liabilitiesFree = filter('liability', 1)
        const liabilitiesTied = filter('liability', 0)

        const totals = {
          assetsFree: calculateTotal(assetsFree),
          assetsTied: calculateTotal(assetsTied),
          liabilitiesFree: calculateTotal(liabilitiesFree),
          liabilitiesTied: calculateTotal(liabilitiesTied)
        }
        totals.assets = totals.assetsFree + totals.assetsTied
        totals.liabilities = totals.liabilitiesFree + totals.liabilitiesTied

        return [
          {
            belongs_to: 'grouptitle',
            type: 'asset',
            title: 'asset',
            amount: null
          },
          {
            belongs_to: 'capital_title',
            type: 'asset',
            title: 'free_capital'
          },
          ...assetsFree,
          {
            belongs_to: 'capital_total',
            type: 'asset',
            title: 'total',
            amount: totals.assetsFree
          },
          {
            belongs_to: 'empty',
            title: null,
            amount: null
          },
          {
            belongs_to: 'capital_title',
            type: 'asset',
            title: 'tied_capital'
          },
          ...assetsTied,
          {
            belongs_to: 'capital_total',
            type: 'asset',
            title: 'total',
            amount: totals.assetsTied
          },
          {
            belongs_to: 'empty',
            title: null,
            amount: null
          },
          {
            belongs_to: 'total',
            type: 'asset',
            title: 'total',
            amount: totals.assets
          },
          {
            belongs_to: 'empty',
            title: null,
            amount: null
          },
          {
            belongs_to: 'grouptitle',
            type: 'liability',
            title: 'liability',
            amount: null
          },
          {
            belongs_to: 'capital_title',
            type: 'liability',
            title: 'free_capital'
          },
          ...liabilitiesFree,
          {
            belongs_to: 'capital_total',
            type: 'liabilitu',
            title: 'total',
            amount: totals.liabilitiesFree
          },
          {
            belongs_to: 'empty',
            title: null,
            amount: null
          },
          {
            belongs_to: 'capital_title',
            type: 'liability',
            title: 'tied_capital'
          },
          ...liabilitiesTied,
          {
            belongs_to: 'capital_total',
            type: 'asset',
            title: 'total',
            amount: totals.liabilitiesTied
          },
          {
            belongs_to: 'empty',
            title: null,
            amount: null
          },
          {
            belongs_to: 'total',
            type: 'liability',
            title: 'total',
            amount: totals.liabilities
          },
          {
            belongs_to: 'empty',
            title: null,
            amount: null
          },
          {
            belongs_to: 'difference',
            title: 'difference',
            amount: totals.assets - totals.liabilities
          }
        ]
      })
      .catch(err => {
        console.error(err)
        return []
      })
  } else {
    return []
  }
}
const fetchTransactionDates = (accountId) => {
  if (accountId) {
    return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/firstAndLastTransaction/${accountId}`)
      .then(response => response.data.data)
      .then(response => {
        return response.length > 0 ? response[0] : {}
      })
      .catch(err => {
        console.error(err)
        return {}
      })
  }
  return {}
}
export default {
  methods: {
    fetchGlobalAccounts: fetchGlobalAccounts,
    fetchInBucket: fetchInBucket,
    fetchIdentityCode: fetchIdentityCode,
    fetchTransactionDates: fetchTransactionDates
  }
}
